<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
  

    <v-layout wrap py-2>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="storage.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + storage.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                    src="../../../assets/images/home.jpg"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ storage.programme.progName }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md4>
                      <span class="textField2"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(storage.bookingDate) }}
                      </span>
                    </v-flex>
                    <v-flex md6>
                      <span class="textField2"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(storage.slotDetail.startTime) }} -
                        {{ formatTime(storage.slotDetail.endTime) }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-2>
                <v-flex xs12 v-for="(type, i) in storage.guestdata" :key="i">
                  <v-layout wrap>
                    <v-flex md4 text-capitalize>
                      <span class="pageForm" style="font-size: 14px">
                        {{ type.Name }}
                      </span>
                      <span class="textField2" style="font-size: 14px">
                        x {{ type.Count }}
                      </span>
                    </v-flex>
                    <v-flex md7 text-right align-self-end>
                      <span class="textField2" style="font-size: 16px">
                        {{ type.Total }} INR
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-2>
                <v-flex md4>
                  <span class="textField2" style="font-size: 14px">
                    Program Total
                  </span>
                </v-flex>
                <v-flex md7 text-right align-self-end>
                  <span style="font-family: poppinssemibold; font-size: 16px">
                    {{ itemTotal }} INR
                  </span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>

              <v-layout wrap py-2 justify-start>
                <v-flex
                  xs12
                  lg11
                  text-left
                  align-self-center
                  style="
                    font-family:poppinslight;
                    font-size: 14px;
                    color: #ff4444;
                  "
                  px-2
                >
                  <span v-if="storage.status != 'Cancelled'">
                    <a
                      :href="
                        'https://api.mudumalaitigerreserve.com/booking/ticketpdf/?id=' +
                        payment._id
                      "
                      style="color: #68d389"
                    >
                      <v-icon color="#68d389" size="16">
                        mdi-arrow-collapse-down
                      </v-icon>
                      Download Ticket
                    </a>
                  </span>
                  <!-- <span v-if="storage.status == 'Cancelled'">
                    Cancelled Program
                  </span>
                  <span
                    v-if="storage.status != 'Cancelled'"
                    class="pl-2"
                    style="cursor: pointer"
                    @click="getOrder"
                  >
                    Cancel Program
                  </span> -->
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
 import ImageLoader from "@/components/Common/imageLoader";

export default {
  props: ["storage", "payment"],
   components: {
   ImageLoader,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      cancelOrderDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
    };
  },
  computed: {
    itemTotal() {
      var total = this.storage.guestdata.reduce(function (prev, cur) {
        return prev + cur.Total;
      }, 0);
      return total;
    },
  },
  methods: {
    getOrder() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cancel/getrefunddata",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          ticketId: this.payment.ticket._id,
          bookingId: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cancelOrderDialog = true;
            this.programData = response.data.data.program;
            this.orderItems = response.data.data.ticket;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    cancelOrder() {
      var bookingId = this.storage._id;
      if (this.cancelAll) bookingId = null;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cancel/request",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          ticketId: this.payment.ticket._id,
          bookingId: bookingId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cancelOrderDialog = false;
            this.$emit("stepper", {
              getData: true,
            });
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
